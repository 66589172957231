@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Open+Sans&dRoboto+Slab&isplay=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* || UTILITIES */
body {
  background-color: #eeefef; }

.mt-2 {
  margin-top: 1em; }

.ml-2 {
  margin-left: 1em; }

.max-width {
  width: 100vw; }

.max-height {
  height: 100vh; }

.bg-gradient-blue-skies {
  background: #56CCF2;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2F80ED, #56CCF2);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.align-self-center {
  align-self: center; }

.justify-content-center {
  justify-content: center; }

.flex {
  display: flex; }

.shadow {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1); }

.text-center {
  text-align: center; }

.position-absolute {
  position: absolute; }

.bottom-0 {
  bottom: 0; }

/*# sourceMappingURL=style.css.map */

.gate {
    width: 300px;
    margin: auto;
    display: block;
    position: relative;
    top: 20px;
    animation-name: gate-slam;
    animation-duration: 1s;
}
.stonearchway {
    width: 400px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin: auto;
    display: block;
}

@keyframes gate-slam {
    0% {top: -1000px;}
    100% {top: 20px;}
}

@keyframes text-appear {
    0% {opacity: 0;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}

.forbiddenmessage {
    color: white;
    font-family: arial;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    animation-name: text-appear;
    animation-duration: 2.5s;
}

h1, h3 {
    margin: 0;
}
